import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import TopBar from '../../components/TopBar';
import Skeleton from '@mui/material/Skeleton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './ProductDetails.css';
import FullWidthTabs from './FullWidthTabs';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import XIcon from '@mui/icons-material/X';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import paymentImg from '../../assets/img/payment.webp';
import Footer from '../../components/Footer';

// Lazy-loaded components
const ProductImage = lazy(() => import('../../components/ProductImage'));
const ProductCategories = lazy(() => import('../../components/ProductCategories'));

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [productCategory, setProductCategory] = useState('');

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`https://admin.aristoc.co.ug:9443/api/v1/products/details/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProduct(data.product);
      } catch (error) {
        console.error('Error fetching product details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch('https://admin.aristoc.co.ug:9443/api/v1/categories');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchProductDetails();
    fetchCategories();
  }, [id]);

  useEffect(() => {
    if (product) {
      // Log the category_ids array
      console.log('Category IDs:', product.category_ids);
    }
  }, [product]);

  const addToCart = () => {
    if (product) {
      const cartItem = {
        id: product.id,
        name: product.name,
        quantity: quantity, // quantity from the user input or default 1
        price: product.price,
        image: product.image
      };
  
      let cart = JSON.parse(localStorage.getItem('cart')) || [];
  
      // Check if the product already exists in the cart
      const existingItemIndex = cart.findIndex(item => item.id === cartItem.id);
  
      if (existingItemIndex !== -1) {
        // If product already exists, update the quantity and price
        cart[existingItemIndex].quantity += cartItem.quantity;
      } else {
        // If product doesn't exist, add it to the cart
        cart.push(cartItem);
      }
  
      localStorage.setItem('cart', JSON.stringify(cart));
  
      // Update the badge count (could be done by triggering a context update or similar)
      window.dispatchEvent(new Event('cartUpdated'));
    }
  };
  

  const handleQuantityChange = (delta) => {
    setQuantity(prevQuantity => Math.max(prevQuantity + delta, 1));
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncateDescription = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const choiceOptions = product ? product.choice_options || [] : [];

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => Number(cat.id) === Number(categoryId));
  return category ? category.name : '';
  };


  return (
    <div>
      <TopBar />
      <div className="product-details-container">
        <div className="product-main-content">
          {/* New Row Wrapper for Info and Image */}
          <div className="product-top-row">

            <div className="product-image-container">
              <Suspense fallback={<Skeleton variant="rectangular" width={400} height={400} />}>
                {product && <ProductImage product={product} />}
              </Suspense>
            </div>
            <div className="product-info-container">
              {loading ? (
                <>
                  <Skeleton variant="text" width={300} height={40} />
                  <Skeleton variant="text" width={250} height={30} />
                  <Skeleton variant="text" width={200} height={30} />
                  <Skeleton variant="rectangular" width={300} height={200} />
                </>
              ) : product ? (
                <>
                  <h1 className="product-detail-title">{product.name}</h1>
                   {/* Chip and Category Name */}
                   <div
  style={{
    display: 'flex',
    alignItems: 'center', // Vertical centering
    justifyContent: 'flex-start', 
    gap: '10px',
    marginBottom: '10px',
  }}
>
  <Chip
    label="Bestseller"
    sx={{
      backgroundColor: 'primary.main',
      color: 'white',
      borderRadius: '0', // Square corners
      clipPath: 'polygon(0 0, 0% 0, 0% 100%, 75% 100%, 100% 0%, 100% 0)', // Custom V shape on the right side
      padding: '8px 16px',
      position: 'relative', // Add position relative
      zIndex: 1, // To ensure the chip stays on top
      height: '20px',
      backgroundColor: '#C45500',
    }}
  /> 
  
  {getCategoryName(product.category_ids[1]?.id) && (
  <p
    className="category-name"
    style={{
      color: '#007185', // Text color
      fontWeight: 500, // Slightly thicker font weight
      fontSize: '14px', // Adjust font size if needed
      margin: 0, // Remove default margin
    }}
  >
    in&nbsp;
    {getCategoryName(product.category_ids[1]?.id)}
  </p>
)}
</div>

                  <Divider style={{ marginBottom: '20px' }} />
                  <div className="product-details-description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? product.description
                          : truncateDescription(product.description, 70),
                      }}
                    />
                    {product.description.split(' ').length > 70 && (
                      <button className="read-more-link" onClick={handleToggleDescription}>
                        {showFullDescription ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                  </div>

                  <p className="product-id">ISBN: {product.tax}</p>

                  <p className="product-details-price">UGX {product.price.toLocaleString()}</p>

                  <div className="buy-container">
                    <div className="buy-items">
                      <div className="quantity-controls">
                        <button className="quantity-button" onClick={() => handleQuantityChange(-1)}>
                          <RemoveIcon />
                        </button>
                        <input type="text" className="quantity-input" value={quantity} readOnly />
                        <button className="quantity-button" onClick={() => handleQuantityChange(1)}>
                          <AddIcon />
                        </button>
                      </div>
                      <div className="icon-controls">
                        <div className="icon-wrapper">
                          <FavoriteBorderIcon className="icon-control" />
                        </div>
                        <div className="icon-wrapper">
                          <CompareArrowsIcon className="icon-control" />
                        </div>
                      </div>
                    </div>
                    <div className="buy-buttons">
                      <button className="buy-button" onClick={addToCart}>Add to Cart</button>
                      <button className="buy-button buy-now-button">Buy Now</button>
                    </div>
                  </div>
                  <div className="share-id">Share: <div>
                    <FacebookRoundedIcon /> <XIcon /> <PinterestIcon /> <LinkedInIcon /> <WhatsAppIcon /> <TelegramIcon />
                  </div>
                  </div>
                  <div className='product-payment'>
                    <img
                      src={paymentImg}
                      alt="Payment"
                    />
                    <div className="text">
                      Guaranteed safe & secure checkout
                    </div>
                  </div>


                </>
              ) : (
                <div>Product not found.</div>
              )}
            </div>
          </div>

          {/* FullWidthTabs placed below the top row */}
          <div className="tabs-container">
            <FullWidthTabs choiceOptions={choiceOptions} />
          </div>
        </div>

        {/* Product Categories on the extreme right */}
        <div className="product-categories-container">
          <div className="search-container">
            <input type="text" className="search-input" placeholder="Search for products" />
            <button className="search-button">
              <SearchOutlinedIcon />
            </button>
          </div>
          <p style={{ fontFamily: 'sans-serif', fontSize: '18px', fontWeight: 'bold', marginTop: '15px' }}>Product Categories</p>
          <Suspense fallback={<Skeleton variant="text" width={200} height={30} />}>
            {categoriesLoading ? (
              <Skeleton variant="rectangular" width="100%" height={100} />
            ) : categories.length === 0 ? (
              <p>No categories found.</p>
            ) : (
              <select
                className="categories-dropdown"
                onChange={(e) => {
                  const selectedCategoryId = e.target.value;
                  if (selectedCategoryId) {
                    window.location.href = `/category/${selectedCategoryId}`;
                  }
                }}
              >
                <option value="">Select a Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            )}
          </Suspense>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
