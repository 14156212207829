import React, { useEffect, useState, useCallback } from 'react';
import TopBar from '../../components/TopBar';
import { useNavigate, useLocation } from 'react-router-dom';
import CategorySlider from './CategorySlider';
import axios from 'axios';
import ProductFilters from '../HomeScreen/Widget/ProductFilters';
import './Shop.css'; // Import external CSS for responsiveness
import Footer from '../../components/Footer';

const BASE_IMAGE_URL_PUBLIC = 'https://admin.aristoc.co.ug:9443/product/';
const BASE_IMAGE_URL_STORAGE = 'https://admin.aristoc.co.ug:9443/storage/product/';

const renderStars = (rating) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const halfStars = rating % 1 !== 0 ? 1 : 0; // One half star if rating has a decimal
  const emptyStars = 5 - fullStars - halfStars; // Remaining stars are empty

  return (
    <div className="star-rating">
      {[...Array(fullStars)].map((_, index) => (
        <span key={index} className="full-star">★</span>
      ))}
      {halfStars === 1 && <span className="half-star">✩</span>} {/* Half star */}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={index} className="empty-star">☆</span>
      ))}
    </div>
  );
};

export default function Shop() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(18);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expandedCategories, setExpandedCategories] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const rating = 4.5; // For now, we are using a static value of 4.5

  const CACHE_DURATION = 60 * 60 * 1000; // Cache duration in milliseconds (1 hour)

  const fetchWithCacheAndRetry = async (url, cacheKey, retries = 3, delay = 2000) => {
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);

    if (cachedData && cachedTimestamp && Date.now() - cachedTimestamp < CACHE_DURATION) {
      return JSON.parse(cachedData);
    }

    try {
      const response = await axios.get(url);
      localStorage.setItem(cacheKey, JSON.stringify(response.data));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now());
      return response.data;
    } catch (error) {
      if (retries > 0) {
        const retryAfter = error.response?.headers['retry-after'];
        const waitTime = retryAfter ? parseInt(retryAfter) * 1000 : delay;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
        return fetchWithCacheAndRetry(url, cacheKey, retries - 1, delay * 2);
      } else {
        throw error;
      }
    }
  };


  const fetchProducts = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const response = await axios.get('https://admin.aristoc.co.ug:9443/api/v1/categories/products/3');
      console.log('Products data:', response.data);
      const productsData = Array.isArray(response.data) ? response.data : [];
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching all products:', error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await axios.get('https://admin.aristoc.co.ug:9443/api/v1/categories', 'categories');
        setCategories(categoriesData.data);
        setLoadingCategories(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    const fetchProducts = async () => {
      try {
        if (location.state) {
          if (location.state.searchResults) {
            setProducts(Array.isArray(location.state.searchResults) ? location.state.searchResults : []);
            setLoadingProducts(false);
          } else if (location.state.categoryId) {
            const response = await axios.get(`https://admin.aristoc.co.ug:9443/api/v1/categories/products/${location.state.categoryId}`);
            const productsData = response.data.flat();
            setProducts(Array.isArray(productsData) ? productsData : []);
            setLoadingProducts(false);
          }
        } else {
          const productsData = await fetchWithCacheAndRetry('https://admin.aristoc.co.ug:9443/api/v1/categories/products/3');
          const flattenedProducts = productsData.flat();

          setProducts(Array.isArray(flattenedProducts) ? flattenedProducts : []);
          setLoadingProducts(false);
        }

        // After fetching products, fetch categories
        await fetchCategories();
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingProducts(false);
        setLoadingCategories(false);
      }
    };

    fetchProducts();
  }, [location.state]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [categoryId]: !prevExpandedCategories[categoryId]
    }));
  };

  const handleCategoryChange = async (categoryId, isChecked) => {
    setLoadingProducts(true);
    if (isChecked) {
      try {
        const response = await axios.get(`https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryId}`);
        const productsData = response.data.flat();
        setProducts(Array.isArray(productsData) ? productsData : []);
      } catch (error) {
        console.error('Error fetching products for category:', error);
      }
    }
    setLoadingProducts(false);
  };

  const renderCategories = (parentId = 0) => {
    return categories
      .filter((category) => category.parent_id === parentId)
      .sort((a, b) => a.name.localeCompare(b.name)) // Sort categories alphabetically by name
      .map((category) => (
        <div key={category.id}>
          <div
            className="category-item"
            onClick={() => toggleCategory(category.id)}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <input
              type="checkbox"
              id={`category-${category.id}`}
              style={{ marginRight: '5px' }}
              onChange={(e) => handleCategoryChange(category.id, e.target.checked)}
            />
            <label htmlFor={`category-${category.id}`}>{category.name}</label>
            {categories.some((subCategory) => subCategory.parent_id === category.id) && (
              <span style={{ marginLeft: 'auto' }}>{expandedCategories[category.id] ? '-' : '+'}</span>
            )}
          </div>
          {expandedCategories[category.id] && (
            <div style={{ marginLeft: '20px' }}>{renderCategories(category.id)}</div>
          )}
        </div>
      ));
  };


  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 18);
  };

  const handleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const ProductImage = ({ imageArray, altText }) => {
    const [imageSrc, setImageSrc] = useState(
      imageArray && imageArray.length > 0 ? `${BASE_IMAGE_URL_PUBLIC}${imageArray[0]}` : ''
    );

    const handleError = () => {
      setImageSrc(
        imageArray && imageArray.length > 0 ? `${BASE_IMAGE_URL_STORAGE}${imageArray[0]}` : ''
      );
    };

    if (!imageArray || imageArray.length === 0) {
      return <div className="no-image">No Image Available</div>;
    }

    return (
      <img
        src={imageSrc}
        alt={altText}
        onError={handleError}
        className="product-image"
      />
    );
  };

  const SkeletonLoader = () => (
    <div className="skeleton-loader">
      <div className="skeleton-image"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-button"></div>
    </div>
  );

  return (
    <>
      <TopBar />
      <div className="category-slider-wrapper">
        <CategorySlider />
      </div>
      <div className="shop-container">
        <div className="sidebar">
          <div className="search-container">
            <input type="text" className="search-input" placeholder="Search for products" />
            <button className="search-button">SEARCH</button>
          </div>
          <div class="book-list">
            <h2 class="book-list-header">Popular in Books</h2>
            <div class="book-list-items">
              <a href="#">Best Books of 2024</a>
              <a href="#">Top New Releases</a>
              <a href="#">Best Sellers</a>
              <a href="#">School Books</a>
              <a href="#">Books Deals</a>
              <a href="#">All Deals in Books</a>
              <a href="#">Books Outlet</a>
              <a href="#">10% off for Student members</a>
            </div>

            <h2 class="book-list-header">More in Books</h2>
            <div class="book-list-items">
              <a href="#">Audiobooks</a>
              <a href="#">Textbooks</a>
              <a href="#">Aristoc Deals</a>
            </div>

            <h2 class="book-list-header">Popular Brands</h2>
            <div class="book-list-items">
              <a href="#">Harry Potter</a>
              <a href="#">Joe Wicks</a>
              <a href="#">Danielle Steel</a>
            </div>

            <h2 class="book-list-header">Reading</h2>
            <div class="book-list-items">
              <a href="#">New Arrivals</a>
              <a href="#">Last 30 days</a>
              <a href="#">Last 90 days</a>
              <a href="#">Next 90 days</a>
            </div>
          </div>
          <p className="category-header">Product Categories</p>
          {loadingCategories ? (
            <div className="skeleton-categories">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </div>
          ) : (
            renderCategories()
          )}
        </div>
        <div className="product-list">
          {!isMobile && <ProductFilters />}
          {loadingProducts ? (
            <div className="products-grid">
              {Array.from({ length: visibleProducts }).map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </div>
          ) : (
            <>
              <div className="products-grid">
                {products.length > 0 ? (
                  products.slice(0, visibleProducts).map((product) => (
                    <div className="product-item" key={product.id}>
                      <div
                        onClick={() => handleProductClick(product.id)}
                        role="button"
                        className="product-item-content"
                        title={product.name}
                      >
                        {product.image && product.image.length > 0 ? (
                          <ProductImage imageArray={product.image} altText={product.name} />
                        ) : (
                          <div className="no-image">No Image Available</div>
                        )}
                        <h4 className="product-title">{product.name}</h4>
                        <p
                          className="product-description"
                          dangerouslySetInnerHTML={{ __html: product.description }}
                        ></p>

                        {renderStars(rating)}

                        <p className="product-price">UGX {product.price.toLocaleString()}</p>
                        <button className="add-to-cart-button">Add To Cart</button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No products found.</div>
                )}
              </div>

              {visibleProducts < products.length && (
                <div className="load-more-container">
                  <button className="load-more-button" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
