import React, { Component } from 'react';
import './HeaderTop.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

export default class HeaderTop extends Component {
  render() {
    return (
      <div className="chesi-header-top chesi-header-part header-with-border">
        <div className="chesi-header-inner">
          <div className="header-col col-left">
            <div className="header-minimenu type-dropdown">
              <ul className="nav">
                <li
                  id="menu-item-14564"
                  data-id="14564"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-dropdown menu-item-14564"
                >
                  <a href="#0">
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                        fontSize: '10px'
                      }}
                    >
                      Languages
                    </span>
                    <span className="nt-icon-up-chevron dropdown-btn"></span>
                  </a>
                  <ul className="submenu depth_0">
                    <li
                      id="menu-item-14565"
                      data-id="14565"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14565"
                    >
                      <a href="#0">
                        <span data-hover="English"></span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-14568"
                  data-id="14568"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-dropdown menu-item-14568"
                >
                  <a href="#0">
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                        fontSize: '10px'
                      }}
                    >
                      Country
                    </span>
                    <span className="nt-icon-up-chevron dropdown-btn"></span>
                  </a>
                  <ul className="submenu depth_0">
                    <li
                      id="menu-item-14569"
                      data-id="14569"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14569"
                    >
                      <a href="#0">
                        <span data-hover="Uganda (UGX)"></span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-16313"
                  data-id="16313"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-dropdown menu-item-16313"
                >
                  <a href="#0">
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                        fontSize: '10px'
                      }}
                    >
                      Quick Help
                    </span>
                    <span className="nt-icon-up-chevron dropdown-btn"></span>
                  </a>
                  <ul className="submenu depth_0">
                    <li
                      id="menu-item-16317"
                      data-id=" and color as needed16317"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16317"
                    >
                      <a href="https://aristoc.ngrok.dev/aristoc/order-tracking/">
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            fontSize: '9px'
                          }}
                        >
                          Order Tracking
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-16314"
                      data-id="16314"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16314"
                    >
                      <a href="https://aristoc.co.ug/">
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            fontSize: '9px'
                          }}
                        >
                          Contact
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-16315"
                      data-id="16315"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16315"
                    >
                      <a href="https://aristoc.co.ug/">
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            fontSize: '9px'
                          }}
                        >
                          FAQ
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-16316"
                      data-id="16316"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16316"
                    >
                      <a href="https://aristoc.co.ug/">
                        <span data-hover="Branches"></span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="header-col col-center"></div>
          <div className="header-col col-right">
            <div className="header-shortcode">
              <a href="#0" className="icon-small-black">
                <FacebookIcon style={{ fontSize: '15px', color: 'black' }} />
              </a>
              <a href="#0" className="social">
                <InstagramIcon style={{ fontSize: '15px', color: 'black' }} />
              </a>
              <a href="#0" className="social">
                <XIcon style={{ fontSize: '15px', color: 'black' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
