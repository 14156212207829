// App.js
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/MyAccountScreen/my-account';
import HomePage from './pages/HomeScreen/home';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Checkout from './pages/CheckoutScreen/Checkout';
import Shop from './pages/ShopScreen/Shop';
import Categories from './pages/CategoriesScreen/Categories';
import MyAccount from './pages/MyAccountScreen/my-account';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Shop />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/shop" element={<Shop />} />
      <Route path='/categories' element={<Categories />} />
      <Route path='/my-account' element={<MyAccount />} />
    </Routes>
  );
}

export default App;
