import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import axios from 'axios';
import './Categories.css';
import voucher from '../../assets/img/6726537.jpg';
import Footer from '../../components/Footer';

const BASE_IMAGE_URL_PUBLIC = 'https://admin.aristoc.co.ug:9443/product/';
const BASE_IMAGE_URL_STORAGE = 'https://admin.aristoc.co.ug:9443/storage/product/';

const renderStars = (rating) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const halfStars = rating % 1 !== 0 ? 1 : 0; // One half star if rating has a decimal
  const emptyStars = 5 - fullStars - halfStars; // Remaining stars are empty

  return (
    <div className="star-rating">
      {[...Array(fullStars)].map((_, index) => (
        <span key={index} className="full-star">★</span>
      ))}
      {halfStars === 1 && <span className="half-star">✩</span>} {/* Half star */}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={index} className="empty-star">☆</span>
      ))}
    </div>
  );
};

export default function Categories() {
  const location = useLocation();
  const categoryId = location.state?.categoryId;
  const [visibleProducts, setVisibleProducts] = useState(14);
  const [categoryName, setCategoryName] = useState('');
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]); // Store products for grid view
  const [subcategories, setSubcategories] = useState([]);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const navigate = useNavigate();

  const CACHE_DURATION = 60 * 60 * 1000; // Cache duration in milliseconds (1 hour)

  const rating = 4.5; // For now, we are using a static value of 4.5

  const fetchWithCacheAndRetry = async (url, cacheKey, retries = 3, delay = 2000) => {
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);

    if (cachedData && cachedTimestamp && Date.now() - cachedTimestamp < CACHE_DURATION) {
      return JSON.parse(cachedData);
    }

    try {
      const response = await axios.get(url);
      localStorage.setItem(cacheKey, JSON.stringify(response.data));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now());
      return response.data;
    } catch (error) {
      if (retries > 0) {
        const retryAfter = error.response?.headers['retry-after'];
        const waitTime = retryAfter ? parseInt(retryAfter) * 1000 : delay;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
        return fetchWithCacheAndRetry(url, cacheKey, retries - 1, delay * 2);
      } else {
        throw error;
      }
    }
  };

  const fetchCategories = useCallback(async () => {
    setLoadingCategories(true);
    try {
      const categoriesData = await fetchWithCacheAndRetry(
        'https://admin.aristoc.co.ug:9443/api/v1/categories',
        'categories'
      );
      setCategories(categoriesData);

      if (categoryId) {
        const selectedCategory = categoriesData.find((category) => category.id === categoryId);
        setCategoryName(selectedCategory ? selectedCategory.name : 'Categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategories(false);
    }
  }, [categoryId]);

  const fetchProducts = useCallback(async (categoryId) => {
    setLoadingProducts(true);
    try {
      const response = await axios.get(
        `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryId}`
      );
      const productsData = response.data.flat();
      setProducts(Array.isArray(productsData) ? productsData : []);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const fetchSubcategories = useCallback(async (parentId) => {
    setLoadingSubcategories(true);
    try {
      const subcategoriesData = await fetchWithCacheAndRetry(
        `https://admin.aristoc.co.ug:9443/api/v1/categories/childes/${parentId}`,
        `subcategories_${parentId}`
      );
      setSubcategories(subcategoriesData);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    } finally {
      setLoadingSubcategories(false);
    }
  }, []);

  const handleCategoryChange = async (categoryId, isChecked) => {
    if (isChecked) {
      await fetchProducts(categoryId); // Fetch products when a category is checked
      await fetchSubcategories(categoryId); // Fetch subcategories when a category is checked
    }
  };

  const renderCategories = (parentId = 0) => {
    return categories
      .filter((category) => category.parent_id === parentId)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => (
        <div key={category.id}>
          <div
            className="category-item"
            onClick={() => toggleCategory(category.id)}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <input
              type="checkbox"
              id={`category-${category.id}`}
              style={{ marginRight: '5px' }}
              onChange={(e) => handleCategoryChange(category.id, e.target.checked)}
            />
            <label htmlFor={`category-${category.id}`}>{category.name}</label>
            {categories.some((subCategory) => subCategory.parent_id === category.id) && (
              <span style={{ marginLeft: 'auto' }}>
                {expandedCategories[category.id] ? '-' : '+'}
              </span>
            )}
          </div>
          {expandedCategories[category.id] && (
            <div style={{ marginLeft: '20px' }}>{renderCategories(category.id)}</div>
          )}
        </div>
      ));
  };

  const renderSubcategories = () => {
    if (loadingSubcategories) {
      return <div>Loading subcategories...</div>;
    }

    return (
      <div className="subcategory-grid">
        {subcategories.slice(0, 12).map((subcategory) => (
          <div key={subcategory.id} className="subcategory-item-container">
            <div className="category-item-rounded">
              <div className="rounded-item-image">
                <img
                  src={subcategory.image_fullpath || 'https://via.placeholder.com/100'}
                  alt={subcategory.name}
                />
              </div>
            </div>
            <p className="rounded-item-title">{subcategory.name}</p>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    fetchCategories();
    if (categoryId) {
      fetchSubcategories(categoryId);
      fetchProducts(categoryId);
    }
  }, [fetchCategories, fetchSubcategories, fetchProducts, categoryId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [categoryId]: !prevExpandedCategories[categoryId],
    }));
  };


  const ProductImage = ({ imageArray, altText }) => {
    const [imageSrc, setImageSrc] = useState(
      imageArray && imageArray.length > 0 ? `${BASE_IMAGE_URL_PUBLIC}${imageArray[0]}` : ''
    );

    const handleError = () => {
      setImageSrc(
        imageArray && imageArray.length > 0 ? `${BASE_IMAGE_URL_STORAGE}${imageArray[0]}` : ''
      );
    };

    if (!imageArray || imageArray.length === 0) {
      return <div className="no-image">No Image Available</div>;
    }

    return (
      <img
        src={imageSrc}
        alt={altText}
        onError={handleError}
        className="product-image"
      />
    );
  };

  const SkeletonLoader = () => (
    <div className="skeleton-loader">
      <div className="skeleton-image"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-button"></div>
    </div>
  );

  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 18);
  };

  const handleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };
  
  return (
    <div>
      <TopBar />
      <div className="shop-container">
        <div className="categories-sidebar">
          <p className="category-header">Product Categories</p>
          {loadingCategories ? (
            <div className="skeleton-categories">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </div>
          ) : (
            renderCategories()
          )}
        </div>
        <div className="category-list">
          <h1 className="categories-title">{categoryName || 'Categories'}</h1>
          {/* <img src={voucher} alt="Banner" className="categories-banner" /> */}

          {renderSubcategories()}

          {/* Grid View for Products */}
          <div className="products-grid">
                {products.length > 0 ? (
                  products.slice(0, visibleProducts).map((product) => (
                    <div className="product-item" key={product.id}>
                      <div
                        onClick={() => handleProductClick(product.id)}
                        role="button"
                        className="product-item-content"
                        title={product.name}
                      >
                        {product.image && product.image.length > 0 ? (
                          <ProductImage imageArray={product.image} altText={product.name} />
                        ) : (
                          <div className="no-image">No Image Available</div>
                        )}
                        <h4 className="product-title">{product.name}</h4>
                        <p
                          className="product-description"
                          dangerouslySetInnerHTML={{ __html: product.description }}
                        ></p>

                        {renderStars(rating)}

                        <p className="product-price">UGX {product.price.toLocaleString()}</p>
                        <button className="add-to-cart-button">Add To Cart</button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No products found.</div>
                )}
              </div>

          {visibleProducts < products.length && (
                <div className="load-more-container">
                  <button className="load-more-button" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}

        </div>
      </div>
      <Footer />
    </div>
  );
}
