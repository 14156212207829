import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'; // For social media icons
import googlePlayIcon from '../assets/img/google-play.png';
import appStoreIcon from '../assets/img/app-store.png';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-left">
                <h3>Social Media</h3>
                <div className="social-media-icons">
                    <FaFacebook />
                    <FaTwitter />
                    <FaInstagram />
                    <FaLinkedin />
                    <FaYoutube />
                </div>

                <h3>Download Apps:</h3>
                <div className="download-icons">
                    <img src={googlePlayIcon} alt="Google Play" className="app-icon" />
                    <img src={appStoreIcon} alt="App Store" className="app-icon" />
                </div>

                <h3>Subscribe to the newsletter</h3>
                <input type="email" placeholder="Enter your email" className="email-input" />

                <div className="help-section">
                    <div className="help-left">
                        <div className="icon-circle">
                            <HeadsetMicIcon className="help-icon" />
                        </div>
                    </div>

                    <div className="help-right">
                        <h3>Need Help? Call Now!</h3>
                        <p className="phone-number">(256) 758042804 (Airtel)</p>
                        <p className="phone-number">(256) 775115719 (MTN)</p>
                    </div>
                </div>

            </div>

            <div className="footer-right">
                <div className="footer-section">
                    <h4>About Aristoc</h4>
                    <ul>
                        <li><a href="#">Aristoc Inside</a></li>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Company</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="#">Career</a></li>
                        <li><a href="#">Brands</a></li>
                        <li><a href="#">Company</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Policies</h4>
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Delivery</a></li>
                        <li><a href="#">Returns</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Brands</h4>
                    <ul>
                        <li><a href="#">Marvel</a></li>
                        <li><a href="#">DC</a></li>
                        <li><a href="#">Disney</a></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Textbooks</h4>
                    <ul>
                        <li><a href="#">Primary</a></li>
                        <li><a href="#">Secondary</a></li>
                        <li><a href="#">International</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Toys</h4>
                    <ul>
                        <li><a href="#">Best Sellers</a></li>
                        <li><a href="#">Offer</a></li>
                        <li><a href="#">Sale</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Accessories</h4>
                    <ul>
                        <li><a href="#">Office</a></li>
                        <li><a href="#">Home & Deco</a></li>
                        <li><a href="#">Outdoor</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Bestsellers</h4>
                    <ul>
                        <li><a href="#">Books</a></li>
                        <li><a href="#">Toys</a></li>
                        <li><a href="#">Accessories</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom"> 
            <p>&copy; 2024 Aristoc Booklex Kampala</p>
            </div>
        </div>
    );
}
