import React, { useState, useEffect } from "react";
import { FaBars, FaChevronDown } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "./HeaderBottom.css";

const HeaderBottom = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [hoveredSubcategoryId, setHoveredSubcategoryId] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Function to toggle dropdown menu
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setShowMobileMenu(!showMobileMenu); // Toggle mobile menu
  };

  // Fetch categories and subcategories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://admin.aristoc.co.ug:9443/api/v1/categories");
        const data = await response.json();

        const simulatedData = data.map((category) => ({
          ...category,
          subcategories: data.filter(
            (subcategory) => subcategory.parent_id === category.id
          ),
        }));

        const enhancedData = simulatedData.map((category) => ({
          ...category,
          subcategories: category.subcategories.map((subcategory) => ({
            ...subcategory,
            subcategories: data.filter(
              (subSubcategory) => subSubcategory.parent_id === subcategory.id
            ),
          })),
        }));

        setCategories(enhancedData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Function to handle mouse enter on a category
  const handleMouseEnterCategory = (categoryId) => {
    setHoveredCategoryId(categoryId);
  };

  // Function to handle mouse leave from a category
  const handleMouseLeaveCategory = () => {
    setHoveredCategoryId(null);
    setHoveredSubcategoryId(null);
  };

  // Function to handle mouse enter on a subcategory
  const handleMouseEnterSubcategory = (subcategoryId) => {
    setHoveredSubcategoryId(subcategoryId);
  };

  // Function to handle mouse leave from a subcategory
  const handleMouseLeaveSubcategory = () => {
    setHoveredSubcategoryId(null);
  };

  const handleCategoryClick = (categoryId) => {
    if (typeof categoryId === 'object') {
      categoryId = categoryId.id; // Adjust this based on your actual data structure
    }
    console.log('Navigating to category:', categoryId);
    navigate('/categories', { state: { categoryId } }); // Navigate to Categories.js
  };

  return (
    <div className="header-bottom">
      <div className="dropdown">
        <button
          onClick={toggleDropdown}
          className={`dropbtn ${isOpen ? "active" : ""}`}
        >
          <FaBars className="icon" /> CATEGORIES <FaChevronDown className="icon" />
        </button>
        <div className={`dropdown-content ${isOpen ? "show" : ""} ${showMobileMenu ? "show-mobile" : ""}`}>
          {categories
            .filter((category) => category.parent_id === 0)
            .map((category) => (
              <div
                key={category.id}
                className="category-item"
                onMouseEnter={() => handleMouseEnterCategory(category.id)}
                onMouseLeave={handleMouseLeaveCategory}
              >
                <img src={category.image_fullpath} alt={category.name} className="category-menu-icon" />
                <a
                  href={`#${category.name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCategoryClick(category.id);
                  }}
                >
                  {category.name}
                </a>
                {hoveredCategoryId === category.id &&
                  category.subcategories.length > 0 && (
                    <div className="subcategory-menu">
                      {category.subcategories.map((subcategory) => (
                        <div
                          key={subcategory.id}
                          className="subcategory-item menu-item"
                          onMouseEnter={() =>
                            handleMouseEnterSubcategory(subcategory.id)
                          }
                          onMouseLeave={handleMouseLeaveSubcategory}
                        >
                          <img src={category.image_fullpath} alt={subcategory.name} className="category-menu-icon" />
                          <a
                            href={`#${subcategory.name}`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryClick(subcategory.id);
                            }}
                          >
                            {subcategory.name}
                          </a>
                          {hoveredSubcategoryId === subcategory.id &&
                            subcategory.subcategories.length > 0 && (
                              <div className="sub-subcategory-menu">
                                {subcategory.subcategories.map((subSubcategory) => (
                                  <a
                                    key={subSubcategory.id}
                                    href={`#${subSubcategory.name}`}
                                    className="menu-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleCategoryClick(subSubcategory.id);
                                    }}
                                  >
                                    <img src={category.image_fullpath} alt={subSubcategory.name} className="category-menu-icon" />
                                    {subSubcategory.name}
                                  </a>
                                ))}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ))}
        </div>
      </div>
      <div className="menu-items left">
        <a href="/" className="home-button">
          HOME
        </a>
        <Link to="/shop" className="home-button">
          SHOP
        </Link>
        <a href="/" className="home-button">
          CONTACTS
        </a>
      </div>
      <div className="menu-items right">
        <a href="/" className="home-button red-text">
          BOOKS
        </a>
        <a href="/" className="home-button red-text">
          TOYS
        </a>
        <a
          href="#"
          className="home-button red-text"
          onClick={handleClick}
        >
          GIFT VOUCHER
        </a>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '10px' }}>
            <Typography variant="body1">
              <a href="/e-gift-voucher" onClick={handleClose} className="red-text">
                E-Gift Voucher
              </a>
            </Typography>
            <Typography variant="body1">
              <a href="/cards" onClick={handleClose} className="red-text">
                Physical Voucher
              </a>
            </Typography>
          </div>
        </Popover>
        <a href="/" className="home-button red-text">
          TEXTBOOKS
        </a>
      </div>
    </div>
  );
};

export default HeaderBottom;
