import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HeaderMiddle.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import logo from '../../../assets/img/aristoc-logo-standart.png';

// Snackbar Alert component
const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#EE1926',
    color: '#fff',
  },
}));

const HeaderMiddle = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null); // To cancel ongoing requests
  const navigate = useNavigate();

  // Fetch product suggestions from API on each keystroke
const fetchSuggestions = async (query) => {
  if (query.trim()) {
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelling the previous request.');
    }

    // Create a new CancelToken source for this request
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      const response = await axios.get(
        `https://admin.aristoc.co.ug:9443/api/v1/products/search`,
        {
          params: { name: query },
          cancelToken: source.token, // Attach cancel token
        }
      );

      const products = response.data.products;

      // Sort the suggestions so that matches starting with the search query are at the top
      const sortedSuggestions = products.sort((a, b) => {
        const aStartsWithQuery = a.name.toLowerCase().startsWith(query.toLowerCase());
        const bStartsWithQuery = b.name.toLowerCase().startsWith(query.toLowerCase());

        if (aStartsWithQuery && !bStartsWithQuery) return -1;
        if (!aStartsWithQuery && bStartsWithQuery) return 1;
        return 0; // If both or neither match, maintain original order
      });

      setSuggestions(sortedSuggestions);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request cancelled:', error.message);
      } else {
        console.error('Error fetching suggestions:', error);
      }
    }
  } else {
    setSuggestions([]);
  }
};


  // Handle input change, immediately fetch suggestions on every keystroke
  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
    fetchSuggestions(newValue);
  };

  // Handle suggestion selection
  const handleSuggestionSelect = (event, value) => {
    if (value) {
      setSearchQuery(value.name);
      navigate('/shop', { state: { searchResults: [value] } });
    }
  };

  // Open popover for cart
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Load cart items from localStorage
  useEffect(() => {
    const updateCart = () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(cart);
      setBadgeCount(cart.reduce((acc, item) => acc + item.quantity, 0));
    };

    updateCart();
    window.addEventListener('cartUpdated', updateCart);

    return () => {
      window.removeEventListener('cartUpdated', updateCart);
    };
  }, []);

  // Handle cart item deletion
  const handleDelete = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setBadgeCount(updatedCart.reduce((acc, item) => acc + item.quantity, 0));
    const event = new Event('cartUpdated');
    window.dispatchEvent(event);
  };

  const totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  // Handle checkout
  const handleCheckout = () => {
    navigate('/checkout', { state: { cartItems, totalPrice } });
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAccountClick = () => {
    navigate('/my-account'); 
};

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="header-middle">
      <div className="mainlogo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="search-container">
        <Autocomplete
          className="search-input"
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => option.name}
          onInputChange={handleInputChange}
          onChange={handleSuggestionSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for products"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    <SearchOutlinedIcon />
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="contact-info">
        <WhatsAppIcon style={{ color: 'green' }} />
        <div className="contact-text">
          <span className="contact-call">Call Now</span>
          <span className="contact-number">+256708695136</span>
        </div>
      </div>
      <div className="icons">
        <CustomBadge badgeContent={badgeCount} color="secondary" onClick={handleClick}>
          <ShoppingCartOutlinedIcon />
        </CustomBadge>
        <CustomBadge badgeContent={0} color="secondary">
          <AutorenewOutlinedIcon />
        </CustomBadge>
        <CustomBadge badgeContent={0} color="secondary">
          <FavoriteBorderOutlinedIcon />
        </CustomBadge>
        <div onClick={handleAccountClick} style={{ cursor: 'pointer' }}>
                <AccountCircleOutlinedIcon />
            </div>
        <CustomBadge badgeContent={0} color="secondary">
          <NotificationsNoneTwoToneIcon />
        </CustomBadge>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 1 }}>
        <div className="cart-items">
  {cartItems.length === 0 ? (
    <div>No items in cart.</div>
  ) : (
    cartItems.map((item) => (
      <div key={item.id} className="cart-item">
        <div className="cart-item-image">
          <img
            src={`https://admin.aristoc.co.ug:9443/product/${item.image[0]}`}
            alt={item.name}
          />
        </div>
        <div className="cart-item-details">
          <span className="cart-item-title">{item.name}</span>
          <span className="cart-item-quantity">{item.quantity}</span>
          <span className="cart-item-price">UGX {item.price}</span>
        </div>
        <DeleteIcon
          className="cart-item-delete"
          onClick={() => handleDelete(item.id)}
        />
      </div>
    ))
  )}
</div>

          <hr className="cart-divider" />
          <div className="cart-subtotal">
            <span>Subtotal: UGX {totalPrice}</span>
          </div>
          <div className="cart-buttons">
            <button className="cart-button">View Cart</button>
            <button
              className="cart-button checkout-button"
              onClick={handleCheckout}
            >
              Checkout
            </button>
          </div>
        </Typography>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HeaderMiddle;
