import React, { useState, useEffect } from 'react';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import './my-account.css';
import MyAccountTab from './my-account-tab';

export default function MyAccount() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [notification, setNotification] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Check for token in localStorage on page load
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    // Automatically clear the notification after 3 seconds
    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => {
                setNotification('');
            }, 3000); // Notification disappears after 3 seconds
            return () => clearTimeout(timer);
        }
    }, [notification]);

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        const loginData = {
            email_or_phone: loginEmail,
            password: loginPassword
        };

        // Send POST request to login endpoint
        fetch('https://admin.aristoc.co.ug:9443/api/v1/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.token) {
                // Store the token in localStorage and update state
                localStorage.setItem('authToken', data.token);
                setNotification('Login successful');
                setIsLoggedIn(true);
            } else {
                setNotification('Login failed');
            }
        })
        .catch(error => {
            console.error('Error during login:', error);
            setNotification('An error occurred. Please try again.');
        });
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        const registrationData = {
            f_name: firstName,
            l_name: lastName,
            email: email,
            phone: phone,
            password: password
        };

        // Send POST request to registration endpoint
        fetch('https://admin.aristoc.co.ug:9443/api/v1/auth/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registrationData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.token) {
                setNotification('User Created Successfully');
            } else {
                setNotification('Registration Failed');
            }
        })
        .catch(error => {
            console.error('Error during registration:', error);
            setNotification('An error occurred. Please try again.');
        });
    };

    return (
        <div>
            <TopBar />

            <div className="account-container">
                <div className="breadcrumb-container">
                    <nav aria-label="Breadcrumbs" className="breadcrumb-trail">
                        <ul className="breadcrumb">
                            <li><a href="#" rel="home">Home</a></li>
                            <li>/</li>
                            <li>My Account</li>
                        </ul>
                    </nav>
                    <h1 className="account-title">My Account</h1>
                </div>

                {/* Notification */}
                {notification && <div className="notification">{notification}</div>}

                {/* Show forms only if user is not logged in */}
                {!isLoggedIn && (
                    <div className="forms-container">
                        <div className="form-column">
                            <h3 className="form-title">Login</h3>
                            <form onSubmit={handleLoginSubmit}>
                                <label htmlFor="login-email">Email or Phone:</label>
                                <input 
                                    type="text" 
                                    id="login-email" 
                                    placeholder="Enter your email or phone" 
                                    value={loginEmail}
                                    onChange={(e) => setLoginEmail(e.target.value)}
                                    required 
                                />
                                <label htmlFor="login-password">Password:</label>
                                <input 
                                    type="password" 
                                    id="login-password" 
                                    placeholder="Enter your password" 
                                    value={loginPassword}
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    required 
                                />
                                <button type="submit">Login</button>
                            </form>
                        </div>
                        <div className="form-column">
                            <h3 className="form-title">Register</h3>
                            <form onSubmit={handleRegisterSubmit}>
                                <div className="name-container">
                                    <div className="input-group">
                                        <label htmlFor="register-firstname">First Name:</label>
                                        <input 
                                            type="text" 
                                            id="register-firstname" 
                                            placeholder="Enter your first name" 
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="register-lastname">Last Name:</label>
                                        <input 
                                            type="text" 
                                            id="register-lastname" 
                                            placeholder="Enter your last name" 
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                </div>
                                <div className="contact-container">
                                    <div className="input-group">
                                        <label htmlFor="register-email">Email:</label>
                                        <input 
                                            type="email" 
                                            id="register-email" 
                                            placeholder="Enter your email" 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="register-phone">Phone:</label>
                                        <input 
                                            type="tel" 
                                            id="register-phone" 
                                            placeholder="Enter your phone number" 
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                </div>
                                <label htmlFor="register-password">Password:</label>
                                <input 
                                    type="password" 
                                    id="register-password" 
                                    placeholder="Enter your password" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required 
                                />
                                <button type="submit">Register</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Show MyAccountTab if the user is logged in */}
                {isLoggedIn && <MyAccountTab />}
            </div>

            <Footer />
        </div>
    );
}
