import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function MyAccountTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        // Centering the container
        margin: '20px auto',
        maxWidth: '900px', // Limiting the width to center
        height: 340, // Adjust height as needed
        boxShadow: 3, // Optional: add a shadow for better visibility
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        // Styling for smaller and closer tabs
        sx={{
            textTransform: 'none',
          borderRight: 1,
          borderColor: 'divider',
          minWidth: '120px', // Set a fixed width for tabs
          padding: '30px',
          '.MuiTab-root': {
            padding: '16px 30px',
            textTransform: 'none',
            minHeight: '15px', // Smaller tab height
            fontSize: '14px', // Reduce font size
            padding: '8px 16px', // Add some padding for spacing
          },
          '.MuiTabs-indicator': {
            width: '4px', // Make the indicator narrower
          },
        }}
      >
        <Tab label="Dashboard" {...a11yProps(0)} />
        <Tab label="Orders" {...a11yProps(1)} />
        <Tab label="Downloads" {...a11yProps(2)} />
        <Tab label="Address" {...a11yProps(3)} />
        <Tab label="Account Details" {...a11yProps(4)} />
        <Tab label="Wishlist" {...a11yProps(5)} />
        <Tab label="Logout" {...a11yProps(6)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        Dashboard
      </TabPanel>
      <TabPanel value={value} index={1}>
        Orders
      </TabPanel>
      <TabPanel value={value} index={2}>
        Downloads
      </TabPanel>
      <TabPanel value={value} index={3}>
        Address
      </TabPanel>
      <TabPanel value={value} index={4}>
        Account Details
      </TabPanel>
      <TabPanel value={value} index={5}>
        Wishlist
      </TabPanel>
      <TabPanel value={value} index={6}>
        Logout
      </TabPanel>
    </Box>
  );
}
