import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Checkout.css';
import TopBar from '../../components/TopBar';
import Modal from '../../components/Modal';

const Checkout = () => {
    const location = useLocation();
    const { cartItems, totalPrice } = location.state || { cartItems: [], totalPrice: 0 };

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [modalUrl, setModalUrl] = useState(null);

    const handlePlaceOrder = async () => {
        try {
            const response = await fetch('https://admin.aristoc.co.ug:9443/api/v1/customer/order/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNTE1YTI0OGRlYTUyMWY0YmI4Mjc5MDk1NmVhZjZkNTQwZjc1MjYyMzk4NWQwZmRjZDMzOTU5N2I0NzlhNTZjZGY5ZDRiMzU3ZWQ0YjllM2UiLCJpYXQiOjE3MjY0ODgwNjAuMDM0OTUyLCJuYmYiOjE3MjY0ODgwNjAuMDM0OTU3LCJleHAiOjE3NTgwMjQwNTkuODc5NjAxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.gdqOsMfymqdLbCfAapP044L5Mjli4HavLSVZhsCs6kstjx2C4ltjAZ8UbVe5RbtmjC80louPPI-PZY3_QqenLbDTzHwnIWv2JUeDHCSWtj-vuKLa3u4cMYOSfJhRLieVlsq0ROoie-7MKT8NJjRHoTqAA1fM6FxF_VBcFmBe5yDWLl02eAI6OdFwmUB_uYAcjBsZBjBCq12-O0oAuyRwP8LXiEwJIB75pLxI6AcUDDadOAxyGSMf76BrYU5QxrD8tQSWRaw1cQUlS5Ud5QdBICAAn2nwN6j8NVFB5iI7wnv52Px07hPYVS8RvjrDCzWSfjWDFgX9xXv1lXxrAA1YMJdD65dTQslVZHEFfH-FyGHYG12XiXGIjS46xoQOs4-OA5gxpFYGIZu6ztTodtL2msm-Br0s9d6NVwsuJ8hVjM_JfWK8q5_6XOuHB7u88UlsjNpNxlZVK5gGMtXseAfxxTHLxNjPGdMDqSJYrhuVq2xC88bMq07QFbuAcJDNqL2jOIKHlB4Hy_JZF3HKu9RyKIxcpd5hP8bxK0TcNZmU6zUEhMpI4hE7i87jiP_ztKRDb30CVCwnHdG_xiKV1WbEu_W5x55BRUJkn-iPWF35ACF89NwxQIe-asyW1191yFzYO0an__UlyVST10-q6ZygEFbVG4qRTsyT5kLNwAk6P-Y',
                },
                body: JSON.stringify({
                    paymentAmount: totalPrice.toString(),
                    paymentCurrency: 'UGX',
                    customerFirstName: firstName,
                    customerLastName: lastName,
                    customerAddress: address,
                    customerCity: 'Kampala',
                    customerPhone: phone,
                    customerEmail: email,
                    companyRef: '54841',
                }),
            });

            const text = await response.text();
            console.log('Response from server:', text);

            if (text) {
                setModalUrl(text);
            } else {
                alert('Unexpected response.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred during the transaction.');
        }
    };

    const handlePayWithFlutterwave = async () => {
        try {
            const paymentData = {
                tx_ref: Date.now().toString(),
                amount: totalPrice.toString(),
                currency: 'UGX', 
                payment_options: 'card,mobilemoney,ussd',
                redirect_url: 'https://admin.aristoc.co.ug:9443/api/v1/pay', 
                customer: {
                    email: email,
                    phonenumber: phone,
                    name: firstName,
                },
                customizations: {
                    title: 'Aristoc Booklex',
                    description: 'Payment for items in cart',
                    logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
                },
            };

            const response = await fetch('https://admin.aristoc.co.ug:9443/api/v1/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            });

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.status === 'success') {
                // Redirect to the modal for payment completion
                setModalUrl(data.redirect_url);
            } else {
                alert('Payment failed.');
            }
        } catch (error) {
            console.error('Payment error:', error);
            alert('An error occurred while processing the payment.');
        }
    };

    return (
        <>
            <TopBar />
            <div className="checkout-container">
                <div className="billing-details">
                    <h2>Billing Details</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="tel" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                    </form>
                </div>

                <div className="order-summary" style={{ width: '65%' }}>
                    <h2>Order Summary</h2>
                    {cartItems.map((item) => (
                        <div key={item.id} className="order-item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', marginBottom: '10px' }}>
                            <div className="order-item-details" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <img src={`https://admin.aristoc.co.ug:9443/product/${item.image[0]}`} alt={item.name} style={{ marginRight: '10px' }} />
                                <span>{item.name}</span>
                            </div>
                            <span className="order-item-price">
                                UGX {item.price} x {item.quantity}
                            </span>
                        </div>
                    ))}

                    <hr />
                    <div className="order-total">
                        <span>Total:</span>
                        <span>UGX {totalPrice}</span>
                    </div>

                    <button className="place-order-button" onClick={handlePayWithFlutterwave}>
                        Pay with Flutterwave
                    </button>

                    <button className="place-order-button" onClick={handlePlaceOrder}>
                        Place Order
                    </button>
                </div>
            </div>

            {modalUrl && (
                <Modal url={modalUrl} onClose={() => setModalUrl(null)} />
            )}
        </>
    );
};

export default Checkout;
